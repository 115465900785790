import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {
  Row, Col,
  Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import SearchPanel from '../components/search-panel'

import Layout from '../components/layout'

import './../css/pages.css'

class SearchPage extends Component {

    render() {

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                return (
                    <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000015")}`, url:this.props.location.href } }>
                        <div id="bodySearch" className="body-page  d-flex align-items-center">
                            <Container className="wrapper">
                                <main role="main" className="main">
                                    <Row className="justify-content-center">
                                        <Col md="8">
                                            <h2>{t('TEXT000015')}</h2>
                                            <SearchPanel />
                                        </Col>
                                    </Row>
                                </main>
                            </Container>
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(SearchPage)

export const query = graphql`
  query searchLang($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
  }
`
